import debounce from 'debounce';

/**!
 * Mobile Menu
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2018
 */
let html = document.querySelector('html');
let mobileMenuIsOpen = false;
let openedClass = 'mobileMenuOpen';
let closedClass = 'mobileMenuClosed';

const openMobileMenu = () => {
	mobileMenuIsOpen = true;
	html.classList.add(openedClass);
	html.classList.remove(closedClass);
};

const closeMobileMenu = () => {
	mobileMenuIsOpen = false;
	html.classList.add(closedClass);
	html.classList.remove(openedClass);
};

const toggleMobileMenu = () => {
	if (mobileMenuIsOpen) {
		openMobileMenu();
	} else {
		closeMobileMenu();
	}
};

export default {
	init({
		     openClass = 'mobileMenuOpen',
		     closeClass = 'mobileMenuClosed',
		     maxWidth = 0,
		     toggleSelector = '[data-mobile-menu-toggle]'
	     } = {}) {

		//override the defaults, if applicable
		openedClass = openClass;
		closedClass = closeClass;

		//set up the mobile menu toggles
		let mobileMenuToggles = document.querySelectorAll(toggleSelector);
		mobileMenuToggles.forEach(mobileMenuToggle => {
			mobileMenuToggle.addEventListener('click', e => {
				e.preventDefault();
				mobileMenuIsOpen = !mobileMenuIsOpen;
				toggleMobileMenu();
			});
		});

		if (maxWidth) {
			const closeIfPastMaxWidth = () => {
				let w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
				if (w >= maxWidth) {
					closeMobileMenu();
				}
			};

			['DOMContentLoaded', 'load', 'resize'].forEach(function(eventName) {
				window.addEventListener(eventName, debounce(closeIfPastMaxWidth, 10, false));
			});

			//initial
			closeIfPastMaxWidth();
		}
	},
	open: openMobileMenu,
	close: closeMobileMenu,
	toggle: toggleMobileMenu
}