const basicScroll = require('basicScroll');

document.querySelectorAll('[data-parallax-scene]').forEach(scene => {
	let varAttr = scene.getAttribute('data-parallax-scene') || 'delta';
	let varNames = varAttr.split('|');

	varNames.forEach(varName => {
		let options = {
			elem: scene,
			from: 'top-middle',
			to: 'bottom-middle',
			props: {
				['--'+varName]: {
					from: '0',
					to: '100%'
				}
			},
			direct: true
		};

		basicScroll.create(options).start()
	});
});