
// ---------- Polyfills ----------
import 'nodelist-foreach-polyfill';
import 'svgxuse';
import 'element-closest';

// ---------- Components ----------

// ---------- js class ----------
import './Components/jsClass';

// ---------- floating header ----------
import floating from './Components/floatingElement';
floating.init();

// ---------- mobile menu ----------
import mobileMenu from './Components/mobileMenu';
mobileMenu.init({
	openClass: 'mobileNavOpen',
	closeClass: 'mobileNavClosed',
	maxWidth: 1024
});

// ---------- map ----------
import simpleMap from './Components/simpleMaps';
simpleMap();

// ---------- lazysizes ----------
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange'
import 'lazysizes/plugins/bgset/ls.bgset'
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/respimg/ls.respimg';
Object.assign(lazySizes.cfg, {loadMode:1});

// ---------- sliders ----------
import './Components/sliders';

// ---------- drawers ----------
import drawers from './Components/drawers';
drawers.init();

// ---------- basicScroll ----------
import './Components/parallax';