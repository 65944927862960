// ---------- Tiny Slider ----------
import {tns} from 'tiny-slider/src/tiny-slider';
import debounce from "debounce";
import Flickity from "flickity";

let sliders = document.querySelectorAll('[data-slider]');
sliders.forEach(slider => {
	let slides = slider.querySelectorAll('.slide');
	if (slides.length < 2) {
		return;
	}

	let autoHeight = false;

	let sliderOptions = {
		container: slider,
		mode: 'carousel',
		controls: false,
		nav: true,
		autoplay: true,
		autoplayButton: false,
		autoplayButtonOutput: false,
		autoplayHoverPause: true,
		speed: 2000,
		autoplayTimeout: 5000,
		mouseDrag: false,
		arrowKeys: true, //change this
		loop: true,
		freezable: true,
		controlsText: ['<svg class="icon icon-arrow-left"><use xlink:href="/dist/svg/icons.svg#arrow-left"></use></svg>', '<svg class="icon icon-arrow-right"><use xlink:href="/dist/svg/icons.svg#arrow-right"></use></svg>'],
		animateIn: 'slide--fadeIn',
		animateOut: 'slide--fadeOut',
		animateNormal: 'slide--fadeNormal',
		autoHeight: false,
		slideBy: 1,
		responsive: {
			768: {
				controls: true
			}
		}
	};

	let sliderType = slider.getAttribute('data-slider');
	let navSlider;
	let hasNavSlider = false;
	if (slider.getAttribute('data-has-nav-slider') === 'true') {
		hasNavSlider = true;
	}

	if (sliderType === 'full') {
		sliderOptions = {
			container: slider,
			mode: 'gallery',
			controls: false,
			nav: false,
			autoplay: true,
			autoplayButton: false,
			autoplayButtonOutput: false,
			autoplayHoverPause: true,
			speed: 2000,
			autoplayTimeout: 5000,
			mouseDrag: false,
			arrowKeys: false,
			loop: true,
			freezable: true,
			animateIn: 'slide--fadeIn',
			animateOut: 'slide--fadeOut',
			animateNormal: 'slide--fadeNormal',
			autoHeight: false
		};
		autoHeight = false;
	} else if (sliderType === 'gallery') {
		sliderOptions = {
			container: slider,
			mode: 'gallery',
			controls: false,
			nav: false,
			autoplay: false,
			autoplayButton: false,
			autoplayButtonOutput: false,
			autoplayHoverPause: true,
			speed: 2000,
			autoplayTimeout: 3000,
			mouseDrag: false,
			arrowKeys: false,
			loop: false,
			freezable: true,
			animateIn: 'slide--fadeIn',
			animateOut: 'slide--fadeOut',
			animateNormal: 'slide--fadeNormal',
			autoHeight: false
		};
		autoHeight = false;
	}

	let tnsSlider = tns(sliderOptions);

	if (hasNavSlider) {
		let sliderGroup = slider.closest('[data-slider-group]');
		if (sliderGroup) {
			//set up the nav slider
			navSlider = sliderGroup.querySelector('[data-nav-slider]');
			if (navSlider) {

				let tnsNavSlider = new Flickity( navSlider, {
					autoPlay: 3000,
					//cellAlign: 'left',
					contain: true,
					pageDots: false,
					prevNextButtons: false,
					wrapAround: false
				});

				//change the gallery image
				tnsNavSlider.on( 'change', function( index ) {
					tnsSlider.goTo(index);
				});

				//listen for clicks
				tnsNavSlider.on( 'staticClick', function( event, pointer, cellElement, cellIndex ) {
					tnsNavSlider.select(cellIndex);
					tnsSlider.goTo(cellIndex);
				});

				//get the nav wrapper to set up custom events
				let navWrapper = sliderGroup.querySelector('[data-nav-wrapper');

				//set up custom buttons
				if (navWrapper) {
					let prevButton = navWrapper.querySelector('[data-controls="prev"]');
					prevButton.addEventListener('click', function() {
						tnsNavSlider.pausePlayer();
						tnsNavSlider.previous(true);
						tnsNavSlider.unpausePlayer();
					});

					let nextButton = navWrapper.querySelector('[data-controls="next"]');
					nextButton.addEventListener('click', function() {
						tnsNavSlider.pausePlayer();
						tnsNavSlider.next(true);
						tnsNavSlider.unpausePlayer();
					});
				}
			}
		}
	}

	if (autoHeight) {
		//watch
		['DOMContentLoaded', 'load', 'resize'].forEach(function() {
			debounce(tnsSlider.updateSliderHeight, 10, false);
		});
	}
});